table {
  td, th {
    vertical-align: middle;
  }
}

td.cell-fit-content {
  width: 0;
  min-width: fit-content;
}
