@use '@material/switch/switch-theme' as mdc-switch-theme;
@use '@material/form-field' as mdc-form-field;
@use '../core/theming/theming';
@use '../core/theming/inspection';
@use '../core/mdc-helpers/mdc-helpers';
@use '../core/typography/typography';
@use '../core/tokens/m2/mdc/switch' as m2-mdc-switch;
@use '../core/tokens/m2/mat/slide-toggle' as m2-mat-slide-toggle;
@use '../core/tokens/token-utils';

@mixin base($theme) {
  .mdc-switch {
    @include mdc-switch-theme.theme(m2-mdc-switch.get-unthemable-tokens());
  }
}

@mixin color($theme) {
  $is-dark: inspection.get-theme-type($theme) == dark;
  $mdc-switch-color-tokens: m2-mdc-switch.get-color-tokens($theme);

  @include mdc-helpers.using-mdc-theme($theme) {
    // Add values for MDC slide toggles tokens
    .mat-mdc-slide-toggle {
      @include mdc-form-field.core-styles($query: mdc-helpers.$mdc-theme-styles-query);
      @include mdc-switch-theme.theme($mdc-switch-color-tokens);

      // MDC should set the disabled color on the label, but doesn't, so we do it here instead.
      .mdc-switch--disabled + label {
        color: inspection.get-theme-color($theme, foreground, disabled-text);
      }

      // Change the color palette related tokens to accent or warn if applicable
      &.mat-accent {
        @include mdc-switch-theme.theme(
            m2-mdc-switch.private-get-color-palette-color-tokens($theme, accent));
      }

      &.mat-warn {
        @include mdc-switch-theme.theme(
            m2-mdc-switch.private-get-color-palette-color-tokens($theme, warn));
      }
    }
  }
}

@mixin typography($theme) {
  $mdc-switch-typography-tokens: m2-mdc-switch.get-typography-tokens($theme);
  $mat-slide-toggle-typography-tokens: m2-mat-slide-toggle.get-typography-tokens($theme);

  //Add values for MDC slide toggle tokens
  .mat-mdc-slide-toggle {
    @include mdc-form-field.core-styles($query: mdc-helpers.$mdc-typography-styles-query);
    @include mdc-switch-theme.theme($mdc-switch-typography-tokens);
    @include token-utils.create-token-values(
      m2-mat-slide-toggle.$prefix,
      $mat-slide-toggle-typography-tokens
    );
  }
}

@mixin density($theme) {
  $density-scale: inspection.get-theme-density($theme);

  .mat-mdc-slide-toggle {
    @include mdc-switch-theme.theme(mdc-switch-theme.density($density-scale));
  }
}

@mixin theme($theme) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-slide-toggle') {
    @include base($theme);
    @if inspection.theme-has($theme, color) {
      @include color($theme);
    }
    @if inspection.theme-has($theme, density) {
      @include density($theme);
    }
    @if inspection.theme-has($theme, typography) {
      @include typography($theme);
    }
  }
}
