.tag {
  display: inline-block;

  margin: 0.25rem 0 0.25rem 0;

  background-color: #e0e0e0;
  color: #212121;

  padding: 5px 10px;
  border-radius: 16px;

  white-space: nowrap;

  &.tag-small {
    padding: 3px 10px;

    font-size: 11px;
    font-weight: 500;

    text-transform: uppercase;
  }

  &:not(:first-child) {
    margin-left: 0.4rem;
  }
}
