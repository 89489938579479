@use '../layout';
@use '../colors' as colors;
@use '../variables' as variables;

.step {
  @extend .flex-row-center;

  padding: 1rem;

  border: 1px solid transparent;
  border-radius: variables.$border-radius;

  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: colors.$border-color;
    background-color: colors.$subtle-background-highlight;
  }

  .step-index {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: 1.1rem;

    width: 40px;
    height: 40px;

    color: colors.$text-on-primary-color;
    background-color: colors.$primary-color;

    border-radius: 100%;

    user-select: none;
  }

  .step-content {
    flex: 1;
    padding: 0 2rem;

    white-space: pre-line;
  }
}
