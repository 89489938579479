@use '../colors' as colors;
@use '../variables' as variables;

.skeleton {
  color: transparent !important;

  user-select: none;

  .skeleton-text,
  .skeleton-text-medium,
  h1, h2, h3, h4, h5, h6 {
    display: inline-block;

    border-radius: 4px;
    background-color: colors.$skeleton-color;
  }

  hr {
    border-color: colors.$skeleton-color;
  }

  .skeleton-text {
    height: 1em;
  }

  .skeleton-text-medium {
    height: 1.5em;
  }

  .skeleton-square-image {
    display: inline-block;

    height: 64px;
    width: 64px;

    background-color: colors.$skeleton-color;
    border-radius: variables.$border-radius;
  }

  .skeleton-button {
    border: 1px solid colors.$border-color;
    border-radius: variables.$border-radius;

    padding: 10px;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
