@import 'swiper/swiper.min.css';
@import 'swiper/css/virtual';
@import 'swiper/css/pagination';
@import 'swiper/css/navigation';

.swiper-slide {
  flex-basis: 0 !important;
}

.swiper-pagination {
  position: static;
  width: auto !important;

  margin: 0 12px;

  display: inline-flex;
  flex-direction: row;
  align-items: center;

  &.too-many {
    width: 20px;

    & > * {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    background: transparent;
    min-width: 15px;
    min-height: 15px;
    border: 1px solid rgba(0,0,0,.85);
    opacity: 1;

    transition: all 0.2s ease-in-out;

    &.swiper-pagination-bullet-active {
      background: rgba(0,0,0,.85);
    }
  }
}
