@use '../colors' as colors;

.nav {
  display: flex;
  flex-direction: column;

  padding-top: 0.5rem;
  padding-bottom: 0;

  .nav-item {
    display: block;
    color: colors.$text-color;

    padding: 1.25rem 1rem;

    user-select: none;
    cursor: pointer;

    white-space: nowrap;

    &:hover {
      background-color: colors.$selected-background-color;
    }

    &.active {
      background-color: colors.$primary-color;
      color: colors.$text-on-primary-color !important;
    }
  }

  a.nav-item {
    text-decoration: none;

    &:active, &:visited, &:focus {
      color: colors.$text-color;
    }
  }

  .nav-group {
    .nav-group-header {
      padding: 1.25rem 1rem;

      white-space: nowrap;
      user-select: none;
    }

    .nav-item {
      padding-left: 3rem;
    }
  }
}

.nav-container {
  &.sub-nav {
    padding: 1.5rem 0 10rem 0;

    border-right-width: 1px;
    border-right-style: solid;

    border-image: linear-gradient(
        to bottom,
        colors.$border-color,
        rgba(0, 0, 0, 0)
    ) 1 100%;
  }
}
