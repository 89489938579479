@import 'bootstrap/scss/mixins/banner';

@include bsBanner('');


// scss-docs-start import-stack
// Configuration
@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
$enable-dark-mode: false;
@import 'bootstrap/scss/_maps';
@import 'bootstrap/scss/_mixins';
@import 'bootstrap/scss/_utilities';

// Layout & components
@import 'bootstrap/scss/_root';
@import 'bootstrap/scss/_reboot';
@import 'bootstrap/scss/_type';
@import 'bootstrap/scss/_images';
@import 'bootstrap/scss/_containers';
@import 'bootstrap/scss/_grid';
@import 'bootstrap/scss/_tables';

// Helpers
@import 'bootstrap/scss/_helpers';

// Utilities
@import 'bootstrap/scss/utilities/api';
// scss-docs-end import-stack

.table {
  margin-bottom: 0;
}
