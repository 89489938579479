@use '../colors' as colors;
@use '../variables' as variables;

$cardBoxPadding: 1.2rem;

.card-box {
  padding: $cardBoxPadding;

  border: 1px solid colors.$border-color;
  border-radius: variables.$border-radius;

  background-color: #fff;

  position: relative;

  overflow: clip;

  &.inline {
    display: inline-flex;
  }

  .card-box-footer {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    margin: $cardBoxPadding #{-1 * $cardBoxPadding} #{-1 * $cardBoxPadding};
    border-top: 0;
  }

  .card-box-ribbon {
    margin: 0;
    background: colors.$accent-color;
    font-size: 80%;
    color: white;
    padding: 0.2em 0;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(30%) translateY(0%) rotate(45deg);
    transform-origin: top left;

    user-select: none;

    &.card-box-ribbon-left {
      left: 0;
      transform: translateX(30%) translateY(0%) rotate(-45deg);
      transform-origin: top right;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      margin: 0 -1px; /* tweak */
      width: 100%;
      height: 100%;
      background: colors.$accent-color;
    }

    &:before {
      right: 100%;
    }

    &.card-box-ribbon-red {
      background: colors.$danger-color;

      &:before,
      &:after {
        background: colors.$danger-color;
      }
    }

    &.card-box-ribbon-yellow {
      background: colors.$warning-color;

      &:before,
      &:after {
        background: colors.$warning-color;
      }
    }
  }

  &.card-box-link {
    cursor: pointer;

    transition: all 0.25s ease-in-out;

    &:hover {
      border-color: colors.$primary-color;
      box-shadow: 0 1px 4px colors.$primary-color;
    }
  }
}

.card-box-grid-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;

  .grid-item {
    margin: 1em;

    user-select: none;

    cursor: pointer;

    transition: all 0.25s ease-in-out;

    &:hover {
      border-color: colors.$primary-color;
      box-shadow: 0 1px 4px colors.$primary-color;
    }
  }

  .card-box-grid-item {
    @extend .card-box;
    @extend .grid-item;

    width: 300px;
  }
}
