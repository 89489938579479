@use '../colors.scss' as colors;

.badge {
  display: inline-block;

  padding: 2px 12px;

  border-radius: 800px;

  white-space: nowrap;

  color: #fff;
  font-weight: bold;
  font-size: 0.8rem;

  user-select: none;

  &.badge-success {
    background: colors.$success-color;
  }

  &.badge-warning {
    color: colors.$text-color;
    background: colors.$warning-color;
  }

  &.badge-danger {
    background: colors.$danger-color;
  }

  &.badge-gray {
    background: colors.$border-dark-color;
  }
}
