@import 'animate.css/animate.min.css';

.animate__fasterer {
  animation-duration: .25s
}

.animate__fadeInLeftExtraSmall {
  animation-name: fadeInLeftExtraSmall;
}

@keyframes fadeInLeftExtraSmall {
  from {
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomExtraSmall {
  animation-name: fadeInBottomExtraSmall;
}

@keyframes fadeInBottomExtraSmall {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
