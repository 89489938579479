@use '../colors' as colors;

.text-normal {
  color: colors.$text-color !important;
}

.text-success {
  color: colors.$success-color !important;
}

.text-danger {
  color: colors.$danger-color !important;
}

.text-primary {
  color: colors.$primary-color !important;
}

.text-accent {
  color: colors.$accent-color !important;
}

.text-muted {
  color: colors.$text-muted-color !important;
}

.text-skeleton {
  color: colors.$skeleton-color !important;
}

.fs-000 {
  font-size: 4rem !important;
  line-height: 4rem !important;
}

.fs-00 {
  font-size: 3.5rem !important;
  line-height: 3.5rem !important;
}

.fs-0 {
  font-size: 3rem !important;
  line-height: 3rem !important;
}

.fs-55 {
  font-size: 1.1rem !important;
  line-height: 1.1rem !important;
}

.fs-65 {
  font-size: 0.9rem !important;
  line-height: 0.9rem !important;
}

.fs-7 {
  font-size: 0.8rem !important;
  line-height: 0.8rem !important;
}

.fs-8 {
  font-size: 0.6rem !important;
  line-height: 0.6rem !important;
}

.fs-9 {
  font-size: 0.4rem !important;
  line-height: 0.4rem !important;
}

.text-new-line {
  white-space: pre-wrap;
}

.text-multiline-content {
  white-space: pre-line;
}

@for $i from 1 through 10 {
  .text-truncate-multiline-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
